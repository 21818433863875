import * as React from "react"
import { Fragment } from "react"
// import { useStaticQuery, graphql, Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import { GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const AwardHero = ({ header, subheader, src, alt }) => {
  let bgImage
  // src can be optional in CMS, error handle
  if (src) {
    bgImage = convertToBgImage(src)
  }

    return (
      <Fragment>
        {/* error handle for no background image in cms */}
        {src ?
          <BackgroundImage
            Tag="section"
            className="bmo-hero hero-half-size not-root-path"
            backgroundColor={`#bedceb`}
            {...bgImage}
            preserveStackingContext
          >
            <div className="bmo-container">
              <div className="hero-banner-container">
                <div className="hero-banner-container--content">
                  <h1>{header}</h1>
                  {/* error handle for optional subheader in cms */}
                  {subheader && <p>{subheader}</p>}
                </div>
              </div>
              {/* <div className="scroll-indicator">
                  <a href="#main-content">
                  <StaticImage
                      src="../images/caret-down.svg"
                      alt="caret-pointing-downwards"
                      objectFit="contain"
                      placeholder="blurred"
                      height={42}
                      width={42}
                      className="scrolldown-p1"
                  />
                  <span className="sr-only">Click here to scroll down to the first section</span>
                  </a>
              </div> */}
            </div>
          </BackgroundImage>
          :
            <section className="bmo-hero hero-half-size not-root-path no-img">
              <div className="bmo-container">
                <div className="hero-banner-container">
                  <div className="hero-banner-container--content">
                    <h1>{header}</h1>
                  {/* error handle for optional subheader in cms */}
                  {subheader && <p>{subheader}</p>}
                  </div>
                </div>
                {/* <div className="scroll-indicator">
                    <a href="#main-content">
                    <StaticImage
                        src="../images/caret-down.svg"
                        alt="caret-pointing-downwards"
                        objectFit="contain"
                        placeholder="blurred"
                        height={42}
                        width={42}
                        className="scrolldown-p1"
                    />
                    <span className="sr-only">Click here to scroll down to the first section</span>
                    </a>
                </div> */}
              </div>
            </section>
          }

        </Fragment>
    )
}

export default AwardHero
