import * as React from "react"

const MetricCards = ({ metrics }) => {
  let totalMetrics = metrics.length
  let cardClass
  // predetermine classname card row # based on number of cards
  // <4, 7-8, all other cases = row of 4
  // 5 - 6, 9 = row of 3
  if (totalMetrics === 5 || totalMetrics === 6 || totalMetrics === 9) {
    cardClass = "grid-of-three"
  } else {
    cardClass = "grid-of-four"
  }

  return (
    <div className={`awards-card-container ${cardClass}`}>
      {
        metrics.map((metric, index) => {

          return (
            <div className="metric-card-parent" key={`metric${index}`}>
              {metric.src && <img src={metric.src} alt={`icon illustration representing ${metric.description}`} className="icon-container" />}
              {
                metric.stat || metric.description
                  ?
                  <div className="metric-container">
                    <h3>
                      {metric.stat && <span className="stat-style">{metric.stat}</span>}
                      {metric.description && <span className="desc-style">{metric.description}</span>}
                    </h3>
                  </div>
                  : null
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default MetricCards