import * as React from "react"
import { graphql } from "gatsby"
import remark from 'remark'
import remarkHTML from 'remark-html'

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeaderNav from "../components/header-nav"
import AwardHero from "../components/hero-award"
import AwardSection from "../components/section-award"

const toHTML = value => remark()
                        .use(remarkHTML)
                        .processSync(value)
                        .toString()

const AwardPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const content = data.markdownRemark.frontmatter
  const footerMetadata = data.site.siteMetadata.awardOptions
  const asMarkdownB1 = content.body_one
  const asHTMLB1 = toHTML(asMarkdownB1)
  const asMarkdownB3 = content.body_three
  const asHTMLB3 = toHTML(asMarkdownB3)
  const asMarkdownB4 = content.body_four
  const asHTMLB4 = toHTML(asMarkdownB4)
  console.log(post)

  return (
    <Layout location={location} footer={footerMetadata}>
      <Seo
        title={content.title}
        description={`Award submission overview for: ${content.title}`}
      />
      <header>
        <HeaderNav bannerColor={content.bmobanner} />
        <AwardHero header={content.heroheader} subheader={content.herosubheader} src={content.heroImage?.src?.childImageSharp?.gatsbyImageData} alt={content.heroImage?.alt} />
      </header>
      <main id="main-content">
        <AwardSection sectionheader={content.sectionheader01} content={asHTMLB1} />
        <AwardSection sectionheader={content.sectionheader02} content={post.html} />
        {/* error handle for section 3 */}
        {
          content.sectionheader03
          &&
          <AwardSection sectionheader={content.sectionheader03} content={asHTMLB3} metrics={content.metrics} featureimg={content.sectionFeature03} />
        }
        {/* error handle for section 4 */}
        {
          content.sectionheader04
            &&
            <AwardSection sectionheader={content.sectionheader04} content={asHTMLB4} img={content.sectionFourImage} />
        }
      </main>
    </Layout>
  )
}

export default AwardPostTemplate

export const pageQuery = graphql`
  query AwardPageBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        awardOptions {
          header
          footer
          buttonOptions {
            copy
            url
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        bmobanner
        body_four
        body_one
        body_three
        heroheader
        herosubheader
        orgs
        types
        sectionheader01
        sectionheader02
        sectionheader03
        sectionFeature03 {
          alt
          src {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
        sectionheader04
        submityear
        heroImage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
        metrics {
          description
          stat
          src
        }
        sectionFourImage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 500)
            }
          }
        }
      }
    }
  }
`
