import * as React from "react"
import { Fragment } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import MetricCards from "./metric-cards"

const AwardSection = ({ sectionheader, content, metrics, img, featureimg }) => {

  return (
    <section className="section--awards">
      <div className="bmo-container">
        <div className="award-content-container">
          {/* error handle for missing header and content */}
          <h2>{sectionheader && sectionheader}</h2>
          {/* if FEATURE IMAGE exists, place first */}
          { featureimg && <GatsbyImage image={featureimg.src?.childImageSharp?.gatsbyImageData} alt={featureimg.alt} className="section-feature-img-container" objectFit="contain" /> }
          {/* if img exists, create parent flex container and img component with content */}
          {img
            ?
            <div className="award-content-container--innerparent">
              <GatsbyImage image={img.src?.childImageSharp?.gatsbyImageData} alt={img.alt} className="award-content-container--image" objectFit="contain" />
              {content && <div dangerouslySetInnerHTML={{ __html: content }} className="award-content-container--content"></div>}
            </div>
            :
            <Fragment>
              {content && <div dangerouslySetInnerHTML={{ __html: content }} className="award-content-container--inner"></div>}
            </Fragment>
          }
          {metrics
            &&
            <MetricCards metrics={metrics} />
          }
        </div>
      </div>
    </section>
  )
}

export default AwardSection